<template>
  <div class="my-footer">
    <my-beian />
  </div>
</template>

<script>
export default {
  components: {
    'my-beian': () => import('@/components/BeiAn')
  }
}
</script>

<style lang="less">
.my-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>